import request from '@/utils/request';
export var getStatBaseData = function getStatBaseData() {
  return request({
    url: '/stat/basedata',
    method: 'get'
  });
};
export var getDailyLife = function getDailyLife() {
  return request({
    url: '/stat/dailyleft',
    method: 'get'
  });
};
export var getChargeStat = function getChargeStat() {
  return request({
    url: '/stat/chargeStat',
    method: 'get'
  });
};
export var getRoomStat = function getRoomStat(params) {
  return request({
    url: '/stat/roomStat',
    method: 'get',
    params: params
  });
};
export var getItemStat = function getItemStat() {
  return request({
    url: '/stat/itemStat',
    method: 'get'
  });
};
export var queryItem = function queryItem(params) {
  return request({
    url: '/stat/queryItem',
    method: 'get',
    params: params
  });
};
export var userItemStat = function userItemStat(params) {
  return request({
    url: '/stat/userItemStat',
    method: 'get',
    params: params
  });
};
export var getBaseData = function getBaseData(params) {
  return request({
    url: '/stat/getbasedata',
    method: 'get',
    params: params
  });
};